<template>
  <div>
    <Intro v-if="introContent" :content="introContent" />
    <CaseStudiesContent v-if="caseStudiesContent" :content="caseStudiesContent" />
  </div>
</template>

<script>
import Intro from '@/components/Intro'
import CaseStudiesContent from '@/components/CaseStudies/CaseStudiesContent'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CaseStudies',
  components: {
    Intro,
    CaseStudiesContent
  },
  computed: {
    ...mapGetters(['getCaseStudiesContent']),
    introContent () {
      return this.getCaseStudiesContent.intro
    },
    caseStudiesContent () {
      return this.getCaseStudiesContent.caseStudies
    }
  },
  methods: mapActions(['loadCaseStudiesContent']),
  created () {
    this.$store.dispatch('loadCaseStudiesContent')
  }
}
</script>
