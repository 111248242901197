<template>
  <div class="layout">
    <div v-if="caseStudiesContent" class="layout__wrapper">
      <h1>{{ caseStudiesContent.title }}</h1>
      <p class="color-grey">{{ caseStudiesContent.longDescription }}</p>
      <h2>Goals</h2>
      <p>{{ caseStudiesContent.goalDescription }}</p>
      <div class="card card--bordered margin-y-medium text-align-left">
        <h3 class="color-red">The problem</h3>
        <div v-html="markedCompiled(caseStudiesContent.problemDescription)" class="text color-red-bold"></div>
      </div>
      <div class="card card--bordered margin-bottom-medium text-align-left">
        <h3 class="color-green">The solution</h3>
        <div v-html="markedCompiled(caseStudiesContent.solutionDescription)" class="text color-green-bold"></div>
      </div>
      <div class="card card--bordered margin-bottom-medium text-align-left">
        <h3 class="color-blue">Going Forward</h3>
        <div v-html="markedCompiled(caseStudiesContent.goingForward)" class="text color-blue-bold"></div>
      </div>
      <router-link to="/case-studies"><div class="button margin-top-small">go back</div></router-link>
    </div>
  </div>
</template>

<script>
import marked from 'marked'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CaseStudy',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getCaseStudiesContent']),
    caseStudiesContent () {
      if (this.data.title) {
        return this.data
      }
      if (this.getCaseStudiesContent.caseStudies) {
        return this.getCaseStudiesContent.caseStudies.find(study => study.fields.slug === this.data.slug).fields
      }
      return null
    }
  },
  methods: {
    ...mapActions(['loadCaseStudiesContent']),
    markedCompiled (text) {
      return text && marked(text)
    }
  },
  created () {
    if (!this.data.title) {
      this.$store.dispatch('loadCaseStudiesContent')
    }
  }
}
</script>
