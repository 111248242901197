<template>
<div class="layout">
  <div class="layout__wrapper">
    <div v-for="(item, index) in content" :key="index" class="cols cols--3-7 team-card">
      <div class="col">
        <img :src="item.fields.image.fields.file.url" :alt="item.fields.name" width="160px">
      </div>
      <div class="col padding-medium">
        <h3>{{ item.fields.name }}</h3>
        <p>{{ item.fields.bio }}</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'TeamContent',
  props: {
    content: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .team-card {
    margin-bottom: $space-medium;
    border: 2px solid $color-green;
    border-radius: $border-radius-card;
  }
</style>
