 <template>
  <div class="layout">
    <div class="layout__wrapper">
        <h1>{{content.title}}</h1>
        <p class="text color-grey">{{content.description}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
