<template>
  <div class="team-wrapper">
    <Intro v-if="introContent" :content="introContent" />
    <TeamContent v-if="teamContent" :content="teamContent" />
  </div>
</template>

<script>
import Intro from '@/components/Intro'
import TeamContent from '@/components/Team/TeamContent'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Team',
  components: {
    Intro,
    TeamContent
  },
  computed: {
    ...mapGetters(['getTeamContent']),
    introContent () {
      return this.getTeamContent.intro
    },
    teamContent () {
      return this.getTeamContent.team
    }
  },
  methods: mapActions(['loadTeamContent']),
  created () {
    this.$store.dispatch('loadTeamContent')
  }
}
</script>
