<template>
  <div>
    <div v-for="(item, index) in content" :key="index" class="layout layout--stripes stripes">
      <div class="layout__wrapper">
        <div class="cols cols--3-7 margin-y-medium">
          <div class="col services-bg">
            <img :src="item.fields.image.fields.file.url" width="250" :alt="item.title">
          </div>
          <div class="col">
            <div class="heading-h3">{{item.fields.title}}</div>
            <p class="text color-grey">{{item.fields.longDesc}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicesContent',
  props: {
    content: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  /*.services-bg {*/
  /*  background: url('~@/assets/services-bg.svg') no-repeat center center;*/
  /*}*/
</style>
