<template>
  <footer class="layout footer">
    <div class="layout__wrapper">
      <Contact :content="contactContent" />
      <section class="margin-top-medium text-align-center">
        <p class="text text--copy">&copy; {{ text }}</p>
      </section>
    </div>
  </footer>
</template>

<script>
import Contact from '@/components/Contact'

export default {
  name: 'AppFooter',
  components: {
    Contact
  },
  props: {
    contactContent: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    text: '2022, APTA technologies'
  })
}
</script>

<style lang="scss" scoped>
  .footer {
    background-color: $color-grey-dark;
  }
</style>
