<template>
  <section class="partners">
    <div class="layout">
      <div class="layout__wrapper">
        <div class="heading-h2">{{ content.intro.title }}</div>
        <p>{{ content.intro.sortDescription }}</p>
      </div>
    </div>
    <div class="layout">
        <div class="layout__wrapper">
          <div class="cols cols--cards">
            <div v-for="(item, index) in content.partners" :key="index" class="cols partners__cards_item">
              <img class="margin-y-small margin-x-small" :src="item.fields.image.fields.file.url" width="120" :alt="item.title" />
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Partners',
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .partners {
    &__cards_item {
      margin-right: 50px;
    }
  }
</style>
