<template>
  <div>
    <Navigation v-on:menuIsOpen="updateVisibility" />
    <template v-if="menuOpen">
      <transition name="fade">
        <router-view/>
        <router-view name="helper"/>
      </transition>
      <AppFooter v-if="contactContent" :contact-content="contactContent" />
    </template>
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter'
import Navigation from '@/components/Navigation'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'app',
  data () {
    return {
      menuOpen: true
    }
  },
  components: {
    Navigation,
    AppFooter
  },
  computed: {
    ...mapGetters(['getContactContent']),
    contactContent () {
      return this.getContactContent
    }
  },
  methods: {
    ...mapActions(['loadContactContent']),
    updateVisibility: function () {
      this.menuOpen = !this.menuOpen
    }
  },
  created () {
    if (!this.getContactContent) {
      this.$store.dispatch('loadContactContent')
    }
  }
}
</script>

<style lang="scss">
  body {
    margin:0;
  }
  .fade-enter-active, .fade-leave-active {
    transition-property: opacity;
    transition-duration: .40s;
  }

  .fade-enter-active {
    transition-delay: .40s;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }
</style>
