import Vue from 'vue'
import VueRouter from 'vue-router'
import EmptyRouterView from '../views/EmptyRouterView.vue'
import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import Team from '../views/Team.vue'
import CaseStudies from '../views/CaseStudies.vue'
import CaseStudy from '../views/CaseStudy.vue'
import Jobs from '../views/Jobs.vue'
import Demo from '../views/Demo'
import NotFound from '../components/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/404',
    component: NotFound
  },
  { path: '*', redirect: '/404' },
  {
    path: '/products',
    name: 'Services',
    component: Services
  },
  {
    path: '/team',
    name: 'team',
    component: Team
  },
  {
    path: '/case-studies',
    component: EmptyRouterView,
    children: [
      {
        name: 'case-studies',
        path: '',
        component: CaseStudies
      },
      {
        name: 'study',
        path: 'study/:slug',
        component: CaseStudy,
        props: (data) => ({ data: data.params })
      }
    ]
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: Jobs
  },
  {
    path: '/demo',
    name: 'demo',
    component: Demo
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

export default router
