<template>
  <section id="contact" class="contact">
    {{content}}
    <div class="heading-h1 color-white">
      {{ content.title }}
    </div>
    <p class="text color-white">
      {{ content.text }}
    </p>
    <a class="button margin-top-small" :href="content.buttonLink">
      {{ content.buttonLabel }}
    </a>
  </section>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .contact {
    text-align: center;
    @include desktop {
      width: 50%;
      margin: 0 auto;
    }
  }
</style>
