<template>
  <main>
    <Intro v-if="introContent" :content="introContent" />
    <Services v-if="servicesContent.intro" :content="servicesContent" />
    <Partners v-if="partnersContent.intro" :content="partnersContent" />
  </main>
</template>

<script>
import Services from '@/components/Home/Services'
import Partners from '@/components/Home/Partners'
import Intro from '@/components/Home/Intro'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    Services,
    Partners,
    Intro
  },
  computed: {
    ...mapGetters(['getHomeContent', 'getServicesContent', 'getPartnersContent']),
    introContent () {
      return this.getHomeContent.intro
    },
    servicesContent () {
      return this.getServicesContent
    },
    partnersContent () {
      return this.getPartnersContent
    }
  },
  methods: mapActions(['loadHomeContent', 'loadServicesContent', 'loadPartnersContent']),
  created () {
    this.$store.dispatch('loadHomeContent')
    this.$store.dispatch('loadServicesContent')
    this.$store.dispatch('loadPartnersContent')
  }
}
</script>
