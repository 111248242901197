<template>
  <div class="jobs-wrapper">
    <Intro v-if="introContent" :content="introContent" />
    <JobsContent v-if="jobsContent && jobsContent.length > 0" :content="jobsContent" />
  </div>
</template>

<script>
import Intro from '@/components/Intro'
import JobsContent from '@/components/Jobs/JobsContent'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Jobs',
  components: {
    Intro,
    JobsContent
  },
  computed: {
    ...mapGetters(['getJobsContent']),
    introContent () {
      return this.getJobsContent.intro
    },
    jobsContent () {
      return this.getJobsContent.jobs
    }
  },
  methods: mapActions(['loadJobsContent']),
  created () {
    if (!this.getJobsContent.jobs && !this.getJobsContent.intro) {
      this.$store.dispatch('loadJobsContent')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
