<template>
  <nav class="layout">
    <div class="layout__wrapper">
      <div class="align align--center-sides">
        <router-link to="/">
          <img src="@/assets/logo.svg" alt="apta logo" width="170px" />
        </router-link>
        <div class="align nav-menu">
            <router-link v-for="(item, index) in items"
                        :key="index"
                        :to="item.link"
                        class="link"
                        >
              {{ item.name }}
            </router-link>
          <!--  the following tag is the button for the demo page  -->
<!--          <router-link :key="demo.name" :to="demo.link" class="button button&#45;&#45;dark margin-left-small">-->
<!--            {{ demo.name }}-->
<!--          </router-link>-->
            <router-link :to="{ hash: '#contact' }" class="button button--dark margin-left-small">
              {{ contact.name }}
            </router-link>
        </div>
        <div
           :class="{ navBarVisible: showNavBar }"
           @click="animateNav"
           class="align nav-menu-mobile">
         <router-link class="home-link" to="/">
           <img src="@/assets/colored-logo.png" alt="apta-technologies logo">
         </router-link>
         <div class="nav-menu-mobile__link-wrapper">
           <router-link v-for="(item, index) in items"
                       :key="index"
                       :to="item.link"
                       class="link"
                       >
             {{ item.name }}
           </router-link>
         </div>
        </div>
        <div
         :class="{ openburger: showNavBar }"
         class="burger"
         @click="animateNav"
          ></div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  data: () => ({
    items: [
      { name: 'APTAAnomaly', link: '/products' },
      { name: 'Team', link: '/team' },
      { name: 'Case Studies', link: '/case-studies' },
      { name: 'Jobs', link: '/jobs' }
    ],
    demo: { name: 'Demo', link: '/demo' },
    contact: { name: 'Contact', link: '/contact' },
    showNavBar: false,
    showCalendar: false
  }),
  methods: {
    animateNav: function () {
      this.showNavBar = !this.showNavBar
      this.$emit('menuIsOpen')
    }
  }
}
</script>

<style lang="scss" scoped>
.burger{
  display: none;
  position: relative;
  width: 20px;
  height: 3px;
  background-color: $color-grey-dark;
  margin-right: 20px;
  border-radius: 10px;
  &::before, &::after {
    position: absolute;
    content: '';
    width: 20px;
    height: 3px;
    background-color: $color-grey-dark;
    border-radius: 10px;
  }
  &::before {
    top: -6px;
  }
  &::after {
    top: 6px;
  }
}

.nav-menu-mobile {
  display: none;
}

@media screen and (max-width: 768px){
  .align img {
    width: 120px;
  }
  .nav-menu {
    display: none;
  }
  .nav-menu-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 2;
    overflow: hidden;
    background-color: $color-white;
    transform: translateY(-200%);
    padding: 20%;
    opacity: 0;
    transition: all 900ms ease;
    .home-link {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 44px;
        margin-bottom: 20px;
      }
    }
    &__link-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      .link {
        color: $color-grey-dark;
        width: 90%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        letter-spacing: 2px;
      }
    }
    .button {
      display: none;
    }
  }
  .burger {
    display: block;
    z-index: 3;
  }
  .navBarVisible {
    transform: translateY(0);
    opacity: 1;
    transition: all 900ms linear;
  }
  .openburger {
    background-color: $color-white;
    &::before {
      transform: translateY(6px) rotate(45deg);
    }
    &::after {
      transform: translateY(-6px) rotate(-45deg);
    }
  }
}
</style>
