import Vue from 'vue'
import Vuex from 'vuex'
const contentful = require('contentful')
const client = contentful.createClient({
  space: 'bnklzqn4v0sg', // This is the space ID
  accessToken: 'QvyGlp1h_foSyZCG5s6G7ZRw546Z7sD7pnHm5IzdASQ' // This is the access token for this space.
})
// preview token: TQV5vcKTEL9RK2gtVVw9kyEgxRv46iupAn2EbOGFvx4

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    homeContent: {
      intro: null,
      contact: null
    },
    servicesContent: {
      intro: null,
      services: null
    },
    partnersContent: {
      intro: null,
      partners: null
    },
    teamContent: {
      intro: null,
      team: null
    },
    caseStudiesContent: {
      intro: null,
      caseStudies: null
    },
    jobsContent: {
      intro: null,
      jobs: null
    },
    contactContent: null
  },
  getters: {
    getHomeContent: state => state.homeContent,
    getServicesContent: state => state.servicesContent,
    getPartnersContent: state => state.partnersContent,
    getTeamContent: state => state.teamContent,
    getCaseStudiesContent: state => state.caseStudiesContent,
    getJobsContent: state => state.jobsContent,
    getContactContent: state => state.contactContent
  },
  mutations: {
    setHomeContent (state, data) {
      state.homeContent.intro = data.intro
      state.homeContent.contact = data.contact
    },
    setServicesContent (state, data) {
      state.servicesContent.intro = data.intro
      state.servicesContent.services = data.services
    },
    setPartnersContent (state, data) {
      state.partnersContent.intro = data.intro
      state.partnersContent.partners = data.partners
    },
    setTeamContent (state, data) {
      state.teamContent.intro = data.intro
      state.teamContent.team = data.team
    },
    setCaseStudiesContent (state, data) {
      state.caseStudiesContent.intro = data.intro
      state.caseStudiesContent.caseStudies = data.caseStudies
    },
    setJobsContent (state, data) {
      state.jobsContent.intro = data.intro
      state.jobsContent.jobs = data.jobs
    },
    setContactContent (state, data) {
      state.contactContent = data
    }
  },
  actions: {
    async loadHomeContent ({ commit }) {
      try {
        const intro = await client.getEntry('7E2EAZF2KsaWtnasiMfs5h')
        const contact = await client.getEntry('2rWTfin5Qs9LT73I7qnAMW')
        if (intro && contact) {
          commit('setHomeContent', { intro: intro.fields, contact: contact.fields })
        }
      } catch (ex) {
        // Handle errors!!!!
      }
    },
    async loadContactContent ({ commit }) {
      try {
        const contact = await client.getEntry('2rWTfin5Qs9LT73I7qnAMW')
        if (contact) {
          commit('setContactContent', contact.fields)
        }
      } catch (ex) {
        // Handle errors!!!!
      }
    },
    async loadServicesContent ({ commit }) {
      try {
        const intro = await client.getEntry('3G3HXgXYHlDszA2RuuXBPy')
        const services = await client.getEntries({ content_type: 'servicesSectionItems' })
        if (intro && services) {
          const sortedServices = services.items.sort((a, b) => (a.fields.order - b.fields.order))
          commit('setServicesContent', { services: sortedServices, intro: intro.fields })
        }
      } catch (ex) {
        // Handle errors!!!!
      }
    },
    async loadPartnersContent ({ commit }) {
      try {
        const intro = await client.getEntry('2kbmMhCHJgHtK27Y7fH3l0')
        const partners = await client.getEntries({ content_type: 'partnersSectionItems' })
        if (intro && partners) {
          commit('setPartnersContent', { partners: partners.items, intro: intro.fields })
        }
      } catch (ex) {
        // Handle errors!!!!
      }
    },
    async loadTeamContent ({ commit }) {
      try {
        const intro = await client.getEntry('4X39UQIwQTQoyHlzwloDaR')
        const team = await client.getEntries({ content_type: 'teamSectionItems' })
        if (intro && team) {
          const sortedTeam = team.items.sort((a, b) => (a.fields.order - b.fields.order))
          commit('setTeamContent', { intro: intro.fields, team: sortedTeam })
        }
      } catch (ex) {
        // handle the error
      }
    },
    async loadCaseStudiesContent ({ commit }) {
      try {
        const intro = await client.getEntry('4O75ipTPj5YIIcgGaPrUYb')
        const caseStudies = await client.getEntries({ content_type: 'caseStudiesItems' })
        if (intro && caseStudies) {
          commit('setCaseStudiesContent', { intro: intro.fields, caseStudies: caseStudies.items })
        }
      } catch (ex) {
        // handle errors
      }
    },
    async loadJobsContent ({ commit }) {
      try {
        const intro = await client.getEntry('46kMLlW80lqZClSK1vMWo8')
        const jobs = await client.getEntries({ content_type: 'jobsSectionItems' })
        if (intro && jobs) {
          commit('setJobsContent', { intro: intro.fields, jobs: jobs.items })
        }
      } catch (ex) {
        // handle erros
      }
    }
  }
})
