<template>
  <section class="services">
    <div class="layout">
      <div class="layout__wrapper">
        <div class="heading-h2">{{ content.intro.title }}</div>
        <p>{{ content.intro.sortDescription }}</p>
      </div>
    </div>
    <div class="layout services__cards">
        <div class="layout__wrapper">
          <div class="cols cols--cards">
            <router-link v-for="(item, index) in content.services"
                 :key="index"
                 to="/products"
                 class="col card card--link"
            >
                <img class="margin-y-small" :src="item.fields.image.fields.file.url" width="420" :alt="item.title" />
                <div class="heading-h3 margin-y-small">{{ item.fields.title }}</div>
                <div class="text color-grey">{{ item.fields.sortDesc }}</div>
            </router-link>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Services',
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .services {
    background-color: $color-grey-light;
    &__cards {
      padding-top: 0;
    }
  }
</style>
