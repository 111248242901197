<template>
  <div  v-if="content" class="layout">
    <div class="layout__wrapper">
      <div class="cols cols--2-reversed">
        <div class="col">
          <img :src="imageSrc" :alt="imageAlt" :title="imageAlt" width="420px">
        </div>
        <div class="col">
          <h1>{{content.title}}</h1>
          <p class="text color-grey">{{content.text}}</p>
          <a :title="content.buttonLabel"
             :href="content.buttonLink"
             class="button margin-top-small">
            {{content.buttonLabel}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {
    imageSrc () {
      return this.content.image.fields.file.url
    },
    imageAlt () {
      return this.content.image.fields.title
    }
  }
}
</script>
