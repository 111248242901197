<template>
  <div class="layout margin-bottom-medium">
    <div class="layout__wrapper">
      <div class="cols cols--cards">
        <div v-for="(item, index) in content" :key="index" class="col card card--bordered">
          <h3>{{ item.fields.title }}</h3>
          <p class="color-grey margin-y-small">{{ item.fields.shortDescription | snippet }}</p>
          <router-link :to="{ name: 'study', params: item.fields}" class="button">
            read more
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'caseStudiesContent',
  props: {
    content: {
      type: Array,
      required: true
    }
  },
  filters: {
    snippet: function (value) {
      return value.slice(0, 200) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
  h3 {
    height: 20%;
  }
</style>
