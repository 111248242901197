<template>
  <div>
    <Intro v-if="introContent" :content="introContent" />
    <ServicesContent v-if="servicesContent" :content="servicesContent" />
  </div>
</template>

<script>
import Intro from '@/components/Intro'
import ServicesContent from '@/components/Services/ServicesContent'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Services',
  components: {
    Intro,
    ServicesContent
  },
  computed: {
    ...mapGetters(['getServicesContent']),
    servicesContent () {
      return this.getServicesContent.services
    },
    introContent () {
      return this.getServicesContent.intro
    }
  },
  methods: mapActions(['loadServicesContent']),
  created () {
    if (!this.getServicesContent.services && !this.getServicesContent.intro) {
      this.$store.dispatch('loadServicesContent')
    }
  }
}
</script>
