<template>
  <div class="jobs-wrapper">
    <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/laxris?embed=true"></div>
  </div>
</template>

<script>

export default {
  name: 'Demo'
}
</script>

<style lang="scss" scoped>
</style>
