<template>
  <div class="layout">
    <div class="layout__wrapper">
      <div v-for="(item, index) in content" :key="index" class="cols cols--3-7 jobs-card">
        <div class="col">
          <h3 class="color-green">{{ item.fields.title }}</h3>
          <div v-html="markedCompiled(item.fields.description)" class="text color-grey-bold"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked'

export default {
  name: 'JobsContent',
  props: {
    content: {
      type: Array,
      required: true
    }
  },
  methods: {
    markedCompiled (text) {
      return marked(text)
    }
  }
}
</script>

<style lang="scss" scoped>
  .jobs-card {
    padding: $space-small $space-medium;
    text-align: left;
    margin-bottom: $space-medium;
    border: 2px solid $color-grey;
    border-radius: $border-radius-card;
  }
</style>
